import { FORM_SUBMIT_STATE } from 'awds'
import { SUPPORTED_FIELD_DATA_TYPES } from '../data-layer/forms/forms-config'
import mapperFormField from '../data-layer/forms/forms-mapper'
import { useTrackingUtils } from '../utils/useTrackingUtils'

export const defaultFormProps = {
  /**
   * Marketo form id
   */
  formId: { type: [Number, String], required: true },
  /**
   * Route path to redirect to when form is successfully submitted (optional)
   */
  formSuccessRedirectPath: { type: String, default: '' },
  resetFormState: { type: Boolean, default: true }
}

export const useForms = (formId: number, emit: Function) => {
  const { localeGlobalContentData } = useGlobalData()
  const { initRecaptcha, recaptcha } = useRecaptcha()

  const { trackEvent } = useTrackingUtils()
  const { locale, messages } = useI18n()
  const marketoFormFields = ref([])
  const utmListCookie = useCookie('utm_list')
  const utmStampCookie = useCookie('utm_stamp')

  const formSuccessRedirectPath = ref()
  const resetFormState = ref(false)

  const formFetchStatus = ref(FORM_SUBMIT_STATE.loading)
  const data = ref({})

  /**
   * Form submit state
   */
  const formSubmitState = ref(FORM_SUBMIT_STATE.start)
  /**
   * First interacted field for tracking
   */
  const firstInteractedField = ref('')

  // To value is required to make the internalFormId update on prop change
  const internalFormId = computed(() => toValue(formId))

  const fetchFormFields = async () => {
    // Skip fetch in server side (prerender)
    if (import.meta.server || !window) {
      return
    }

    if (!internalFormId.value) {
      return
    }

    // Avoid duplicated calls
    if (data.value[internalFormId.value]) {
      return
    }

    try {
      formFetchStatus.value = FORM_SUBMIT_STATE.loading
      const response = await $fetch<any>('/api/v3/marketo/form-fields', {
        query: {
          formId: internalFormId.value
        }
      })
      data.value[internalFormId.value] = response?.result
      marketoFormFields.value = response?.result || []
      formFetchStatus.value = FORM_SUBMIT_STATE.success
    } catch (e) {
      formFetchStatus.value = FORM_SUBMIT_STATE.fail
      console.error(e)
      // throw new Error(e.message)
    } finally {
      initRecaptcha()
    }
  }

  // If prop changes, internalFormId changes and then the fecth is triggered
  watchEffect(() => {
    fetchFormFields()
  })

  // If we prerender, the fields are empty (does not wait for them to be downloaded)
  // but the formId exists. This results on the fact that when hydrating on the client
  // as the formId has not changed it does not fetch again the fields.
  // We force an extra fech on the client with the onMounted
  onMounted(async () => {
    await fetchFormFields()
  })

  const formLabels = computed(() => {
    return {
      ...localeGlobalContentData.value?.filterLabels,
      ...localeGlobalContentData.value?.formLabels
    }
  })
  const formFieldsHidden = computed(() => {
    return marketoFormFields.value?.filter((field) => field.dataType === 'hidden') || []
  })
  /**
   * Get form fields
   * creates an array with all fields information to be used in the form component
   * @returns {Array}
   */
  const formFields = computed(() => {
    if (!marketoFormFields.value) {
      return []
    }

    // map only the translations that correspond to form fields
    // object key should match the `translationKey` field config defined in `./config.js` file
    const translations = {
      countries: messages.value[locale.value]?.countries,
      companySegments: messages.value[locale.value]?.companySegments,
      salesChannelSingle: messages.value[locale.value]?.salesChannelSingle,
      productNeeds: messages.value[locale.value]?.productNeeds,
      platformActiveUsers: messages.value[locale.value]?.platformActiveUsers,
      industry: messages.value[locale.value]?.industry,
      partnerTypes: messages.value[locale.value]?.partnerTypes,
      partnerChannel: messages.value[locale.value]?.partnerChannel,
      ecommercePlatform: messages.value[locale.value]?.ecommercePlatform,
      preferredLanguage: messages.value[locale.value]?.preferredLanguage,
      department: messages.value[locale.value]?.department
    }

    return (
      marketoFormFields.value
        .filter((field) => SUPPORTED_FIELD_DATA_TYPES.includes(field.dataType))
        .map((field) => mapperFormField(field, formLabels.value, translations, locale.value)) || []
    )
  })

  /**
   * Get form hidden parameters,
   * creates an object with all hidden fields information to be used in the submit method
   * @returns {Object}
   */
  function getFormHiddenParams() {
    if (import.meta.server || !window) {
      return
    }
    const w = window as any
    // ---- 1 - googleID (google analytics client id) ------ //
    const googleClientId = w.ga && w.ga.getAll()[0].get('clientId')
    // ---- 2 - Google Click ID (GCLID) if is not in the url get it from sessionStorage ------ //
    const searchParams = new URLSearchParams(document.location.href)
    const gclidParam = searchParams.get('gclid')
    const gclid = gclidParam || w.sessionStorage.getItem('gclid') || ''

    // Hidden values
    const hiddenValues = {
      gaid: googleClientId,
      Google_Analytics_Client_ID__c: googleClientId,
      gCLID: gclid,
      LastUTMArray__c: utmListCookie.value || '',
      InitialLeadSourceDetail: utmStampCookie.value?.initial_utm_source || '',
      Initial_Lead_Source_Detail__c: utmStampCookie.value?.initial_utm_source || '',
      InitialLeadCampaign: utmStampCookie.value?.initial_utm_campaign || '',
      Initial_Campaign__c: utmStampCookie.value?.initial_utm_campaign || '',
      InitialLeadSource: utmStampCookie.value?.initial_utm_medium || '',
      InitialLeadSource__c: utmStampCookie.value?.initial_utm_medium || '',
      InitialPartnerSource__c: utmStampCookie.value?.initial_utm_partner || '',
      CurrentLeadSourceDetail: utmStampCookie.value?.current_utm_source || '',
      Current_Lead_Source_Detail__c: utmStampCookie.value?.current_utm_source || '',
      CurrentLeadCampaign: utmStampCookie.value?.current_utm_campaign || '',
      Current_Campaign__c: utmStampCookie.value?.current_utm_campaign || '',
      CurrentLeadSource: utmStampCookie.value?.current_utm_medium || '',
      CurrentLeadSource__c: utmStampCookie.value?.current_utm_medium || '',
      PartnerSource: utmStampCookie.value?.current_utm_partner || '',
      PartnerSource__c: utmStampCookie.value?.current_utm_partner || '',
      lastFormReferrerURL: `${w.location.origin}${w.location.pathname}`
    }

    // Values from form hidden fields
    const formFieldsHiddenValues = [...formFieldsHidden.value.values()]

    // Compute hidden params
    return formFieldsHiddenValues.reduce((acc, curr) => {
      const item = {
        [curr.id]: Object.keys(hiddenValues).includes(curr.id) ? hiddenValues[curr.id] : curr.autoFill.value
      }
      return { ...acc, ...item }
    }, {})
  }

  /**
   * Submit form,
   * submits form data to the marketo endpoint and updates form submit state
   *
   * Documnetation: https://developers.marketo.com/rest-api/lead-database/leads/#submit_form
   */
  async function formSubmit({ formData, formFields }: { formData: any; formFields: any }) {
    if (import.meta.server || !window) {
      return
    }
    const w = window as any

    // Format specific form fields to match Marketo expected data structure
    const formattedFormData = Object.keys(formData).reduce((acc, fieldName) => {
      const formField = formFields.find((f) => f.name === fieldName)
      if (formField && formField.multiple && formData[fieldName]) {
        // select multiple fields should be sent as string separated by `; `
        acc[fieldName] = formData[fieldName].join('; ')
      } else {
        acc[fieldName] = formData[fieldName]
      }
      return acc
    }, {})

    // complete form payload to be sent
    const formPayload = {
      formId: internalFormId.value,
      formData: { ...formattedFormData, ...getFormHiddenParams() },
      // cookie: this.$cookie.get('_mkto_trk'),
      visitorData: {
        pageURL: `${w.location.origin}${w.location.pathname}`,
        queryString: w.location.search,
        userAgentString: w.navigator.userAgent
      }
    }

    // on local response with success state
    if (useRuntimeConfig().public.isDev) {
      formSubmitState.value = FORM_SUBMIT_STATE.success
      // Redirect in case a path or url is provided
      if (formSuccessRedirectPath.value) {
        if (formSuccessRedirectPath.value.startsWith('/')) {
          // Navigation between same layer pages
          await navigateTo({ path: `${formSuccessRedirectPath.value}` })
        } else {
          // Navigation cross layer or to other subdomians
          await navigateTo(formSuccessRedirectPath.value, { external: true })
        }
        return
      }
      // emit success event
      emit('success', { formData: formPayload.formData })
      return
    }

    let recaptchaToken
    try {
      recaptchaToken = await recaptcha.value!.execute('submit')
      if (!recaptchaToken) {
        return
      }
    } catch {}
    // set state loading
    formSubmitState.value = FORM_SUBMIT_STATE.loading

    try {
      const data = await $fetch('/api/v3/marketo/submit-form', {
        method: 'POST',
        body: { formPayload, recaptchaToken }
      })
      if (data?.result?.find((i) => ['created', 'updated'].includes(i.status))) {
        // set state success when the result status is created
        formSubmitState.value = FORM_SUBMIT_STATE.success
        // form event tracking
        trackForm(FORM_SUBMIT_STATE.success, formPayload)
        // Redirect in case a path is provided
        if (formSuccessRedirectPath.value) {
          if (formSuccessRedirectPath.value.startsWith('/')) {
            // Navigation between same layer pages
            await navigateTo({ path: `${formSuccessRedirectPath.value}` })
          } else {
            // Navigation cross layer or to other subdomians
            await navigateTo(formSuccessRedirectPath.value, { external: true })
          }
        } else {
          // emit success event (if there is no redirect rule)
          emit('success', { formData: formPayload.formData })
        }
      } else {
        // set state fail
        formSubmitState.value = FORM_SUBMIT_STATE.fail
        // form event tracking
        trackForm(FORM_SUBMIT_STATE.fail, formPayload)
      }
    } catch (error) {
      // set state fail
      formSubmitState.value = FORM_SUBMIT_STATE.fail
      // form event tracking
      trackForm(FORM_SUBMIT_STATE.fail, formPayload)
    } finally {
      // reset state
      if (resetFormState.value) {
        setTimeout(() => {
          formSubmitState.value = FORM_SUBMIT_STATE.start
        }, 5000)
      }
    }
  }

  function trackForm(state, data = {}) {
    // Track form success
    trackEvent({
      event: 'form',
      form: {
        state,
        id: data?.formId,
        data: { ...data?.formData, firstInteractedField: firstInteractedField.value }
      }
    })
  }

  function trackFormInteraction(event: any) {
    trackEvent({
      event: 'form_interacted',
      form: {
        id: internalFormId.value,
        data: { ...event, ...getFormHiddenParams() }
      }
    })
    firstInteractedField.value = event.fieldName
  }

  // onBeforeUnmount(() => {
  //   if (recaptcha.value) {
  //     console.log(recaptcha.value)
  //     // recaptcha.value.destroy()
  //   }
  // })

  return {
    internalFormId,
    FORM_SUBMIT_STATE,
    formLabels,
    marketoFormFields,
    formFields,
    formSubmitState,
    firstInteractedField,
    defaultFormProps,
    formFieldsHidden,
    formFetchStatus,
    // fetchInitialData,
    formSuccessRedirectPath,
    formSubmit,
    trackForm,
    trackFormInteraction
  }
}
