import { load } from 'recaptcha-v3'
import type { ReCaptchaInstance } from 'recaptcha-v3'

export const useRecaptcha = () => {
  const RECAPTCHA_SITE = useRuntimeConfig().public.recaptchaSiteKey

  // We don't want to share the captcha accross all of the forms
  const recaptcha = ref<ReCaptchaInstance | null>(null)

  const initRecaptcha = async (options = {}) => {
    if (import.meta.server || !!recaptcha.value) {
      return
    }
    const _recaptcha = await load(RECAPTCHA_SITE, {
      autoHideBadge: true,
      ...options
    })

    if (_recaptcha) {
      recaptcha.value = _recaptcha
    }
  }

  return {
    initRecaptcha,
    recaptcha
  }
}
